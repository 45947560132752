import { Fragment, useState } from "react"
import { CRUDModalSection, CloseButton, ReadButton, InfoItemHorizontal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import { baseData } from "../utils"

export function Detail({ data }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <ReadButton icon onClick={() => setOpen(true)} />
      {isOpen && <Modal data={data} toggle={() => setOpen(false)} />}
    </Fragment>
  )
}

function Modal({ data, toggle }) {
  // Form
  const { control, setValue } = useForm({
    defaultValues: data
  })
  console.log("tt", control)

  return (
    <CRUDModalSection
      show
      type="READ"
      title={baseData.title}
      onHide={toggle}
    >
      <FormProvider control={control} setValue={setValue}>
        <InfoItemHorizontal label="Code" width={200} text={control?._defaultValues?.payment_term_vendor_code ?? "-"} />
        <InfoItemHorizontal label="Payment Term Vendor" width={200} text={control?._defaultValues?.payment_term_vendor_name ?? "-"} />
        <InfoItemHorizontal label="Description" width={200} text={control?._defaultValues?.description ?? "-"} />
        <hr className='my-3'/>
        <div className="mt-3 d-flex">
          <CloseButton label="Close" onClick={toggle} />
        </div>
      </FormProvider>
    </CRUDModalSection>
  )
}