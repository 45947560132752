import { Fragment, useState } from "react"
import { Modal } from "react-bootstrap"
import { Alert, CloseButton, DeleteButton, InfoItemHorizontal } from "components"
import { FormProvider, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import Services from "services"

export function Delete({ data, onSuccess }) {
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <DeleteButton icon onClick={() => setOpen(true)} />
      {isOpen && <ModalSection data={data} onSuccess={onSuccess} toggle={() => setOpen(false)} />}
    </Fragment>
  )
}

function ModalSection({ data, onSuccess, toggle }) {
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.delete(`/paymentterm/delete?payment_term_id=${data.payment_term_id}`, value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(() => {
        toggle()
        onSuccess()
      }).catch(() => {}).finally(resolve)
    })
  }
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: data
  })

  return (
    <Modal show onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title
          className='d-flex align-items-center font-bold text-uppercase'
          style={{
            fontSize: "15px",
            textShadow: "0 0.5px 0.5px #000"
          }}
        >
          <b className='text-uppercase' style={{ fontSize: "15px", textShadow: "0 0.5px 0.5px #000" }}>Confirmation</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <h5 className="mb-4">Apakah anda yakin akan menghapus data ini?</h5>

          <FormProvider control={control} setValue={setValue}>
            <InfoItemHorizontal label="Code" width={200} text={control?._defaultValues?.payment_term_code ?? "-"} />
            <InfoItemHorizontal label="Payment Term Customer" width={200} text={control?._defaultValues?.payment_term_name ?? "-"} />
            <InfoItemHorizontal label="Description" width={200} text={control?._defaultValues?.description ?? "-"} />
          </FormProvider>

          <Alert show textCenter text="Data akan dihapus secara permanen dan tidak dapat dikembalikan !" variant="danger" />

          <hr className='my-3'/>
          <div className="d-flex">
            <CloseButton icon onClick={toggle} />
            <DeleteButton icon forModal label="Delete" onClick={handleSubmit(onSubmit)} />
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}