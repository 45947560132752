import { Fragment } from "react"
import Services from "services"
import { CRUDLayout, CRUDModalSection, DataStatus, SaveButton, TBody, THead, TableLayout, Td, TdFixed, Th, ThFixed, Tr } from "components"
import { Delete, Detail, FormSection, Update } from "./components"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import toast from "react-hot-toast"
import { baseData, useList, useToggle, validationSchema } from "./utils"

export function PriceLevelVendor() { 
  const { data, isError, isLoading, refetch } = useList()
  const { isActive, toggle } = useToggle()

  if (isError || isLoading) {
    return <DataStatus loading={isLoading} text={isLoading ? "Memuat Data" : "Tidak dapat memuat data"} />
  } else {
    return (
      <Fragment>
        <TableLayout noMarginTop label={baseData.title} withCreateButton onClick={toggle}>
          <div className="px-3 pt-3">
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th>CODE</Th>
                  <Th>PRICE LEVEL VENDOR</Th>
                  <Th>DESCRIPTION</Th>
                  <ThFixed>ACTION</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {data.map((val, index) => {
                  return (
                    <Tr key={val.id}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.price_level_vendor_code ?? "-"}</Td>
                      <Td>{val.price_level_vendor_name ?? ""}</Td>
                      <Td>{val.description ?? "-"}</Td>
                      <TdFixed>
                        <div className="d-flex">
                          <Detail data={val} />
                          <Update data={val} onSuccess={refetch} />
                          <Delete data={val} onSuccess={refetch} />
                        </div>
                      </TdFixed>
                    </Tr>
                  );
                })}
              </TBody>
            </CRUDLayout.Table>
          </div>
        </TableLayout>

        {isActive && (
          <Create
            toggle={toggle}
            onSuccess={() => {
              toggle()
              refetch()
            }}
          />
        )}
      </Fragment>
    )
  }
}

function Create({ onSuccess, toggle }) {
  const defaultValues = {
    price_level_vendor_code: "",
    price_level_vendor_name: "",
    description: ""
  }
  const onSubmit = (value) => {
    return new Promise((resolve) => {
      toast.promise(
        Services.post("/pricelevelvendor/add", value),
        {
          loading: "Loading...",
          success: (res) => res.data.message,
          error: (err) => {
            if (err.response && err.response.status === 400) {
              return err.response.data.message || "Bad Request";
            } else {
              return "An error occurred";
            }
          }
        }
      ).then(onSuccess).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit, setValue } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <CRUDModalSection
      show
      type="create" 
      title={baseData.title}
      onHide={toggle}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormProvider control={control} setValue={setValue}>
          <FormSection />
          <hr className='my-3'/>
          <div className="d-flex">
            <SaveButton icon forModal type="submit" loading={formState.isSubmitting} />
          </div>
        </FormProvider>
      </form>
    </CRUDModalSection>
  )
}